import Helmet from 'react-helmet';
import { PropTypes } from "prop-types";
import { replaceHelmetTokens } from "@/libs/utils";
import metaConfig from '@/routes/metadata.json';

const MetaTags = ({index,properties,meta}={}) => {
    if(!meta) meta=metaConfig[index];
    //console.log("MetaData",meta?.title,replaceHelmetTokens(meta?.title, properties))
    return (
        <Helmet>
            <title>{replaceHelmetTokens(meta?.title, properties)}</title>
            <meta name="description" content={replaceHelmetTokens(meta?.description, properties)} />
      </Helmet>)
};

MetaTags.propTypes = {
    index: PropTypes.string,
    properties: PropTypes.object,
    meta: PropTypes.object,

};

export default MetaTags;