import axios from 'axios';
const BASE_URL = import.meta.env.VITE_API_URL;
class JsonApiService {
  constructor() {
    this.userToken = null;
    this.guestUserId = null;
  }

  setUserToken(token) {
    this.userToken = token;
  }

  getHeaders() {
    let headers = {}
    if (this.userToken) headers['Authorization'] = `Bearer ${this.userToken}`;
    return headers;
  }

  async fetchResource(resource, id = null, params = {}) {
    try {
      const url = id ? `${BASE_URL}/${resource}/${id}` : `${BASE_URL}/${resource}`;
      const response = await axios.get(url, { params, headers: this.getHeaders() });
      return response.data;
    } catch (error) {
      console.error(`Error fetching resource1: ${error}`);
      throw error;
    }
  }

  async createResource(resource, data) {
    try {
      const response = await axios.post(`${BASE_URL}/${resource}`, data, { headers: this.getHeaders() });
      return response.data;
    } catch (error) {
      console.error(`Error creating resource: ${error}`);
      throw error;
    }
  }

  async updateResource(resource, id, data) {

    try {
      const response = await axios.patch(`${BASE_URL}/${resource}/${id}`, data, { headers: this.getHeaders() });
      return response;
    } catch (error) {
      console.error(`Error updating resource: ${error}`);
      throw error;
    }
  }

  async deleteResource(resource, id = null) {

    try {
      let url = `${BASE_URL}/${resource}`;
      if (id) {
        url = `${BASE_URL}/${resource}/${id}`;
      }
      const response = await axios.delete(url, { headers: this.getHeaders() });
      return response;
    } catch (error) {
      console.error(`Error deleting resource: ${error}`);
      throw error;
    }
  }



  async generateImage({ prompt }) {
    try {
      const response = await axios.post(`${BASE_URL}/generate-image`, { prompt }, { headers: this.getHeaders() });
      return response.data;
    } catch (error) {
      console.error(`Error: ${error}`);
      if (error?.response?.data?.error) {
        // If the error is due to an API response, return the entire response as the error
        console.log(error.response.status);
        throw error?.response?.data?.error;
      } else {
        // If the error is not due to an API response, just return the error message
        throw new Error(error.message);
      }
    }
  }
  async saveImage({ prompt }) {
    try {
      const response = await axios.post(`${BASE_URL}/save-image`, { prompt }, { headers: this.getHeaders() });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

}

const jsonApiService = new JsonApiService();

export default jsonApiService;