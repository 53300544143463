import { createSlice } from '@reduxjs/toolkit';



const initialState = {
  kindeUser:null,
  profile: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setKindeUser: (state, action) => {
      state.kindeUser = action.payload;
    },    
    resetKindeUser: (state) => {
      state.kindeUser = null;
    },       
    setProfile: (state, action) => {
      state.profile = action.payload;
      state.isProfileLoaded = true;
 
    },
    updateProfile: (state, action) => {
      state.profile = {...state.profile, ...action.payload};
      state.isProfileLoaded = true;
    },    
    resetProfile: (state) => {
      state.userProfile = null;
      state.isProfileLoaded = false;
    },
  },
});

export const {  setProfile, resetProfile, setKindeUser, resetKindeUser,updateProfile } = userSlice.actions;

export default userSlice.reducer;
